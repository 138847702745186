import {
  BriefcaseIcon,
  ColorSwatchIcon,
  HomeIcon,
  LogoutIcon,
  PencilAltIcon,
  ViewListIcon,
} from "@heroicons/react/outline";
import {} from "@heroicons/react/solid";
import { NavLink } from "react-router-dom";
import { useAuth } from "../context/AuthProvider";

const navigation = [
  {
    name: "Metadata",
    href: "/metadata",
    icon: ColorSwatchIcon,
    current: false,
  },
  { name: "Subjects", href: "/subjects", icon: ViewListIcon, current: false },
  {
    name: "Blogposts",
    href: "/blogposts",
    icon: PencilAltIcon,
    current: false,
  },
  {
    name: "SSYK",
    href: "/ssyk",
    icon: BriefcaseIcon,
    current: false,
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Navigation() {
  const { onLogout } = useAuth();

  return (
    <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
      {/* Sidebar component, swap this element with another sidebar if you like */}
      <div className="flex flex-col flex-grow pt-5 bg-primary-700 overflow-y-auto">
        <div className="flex items-center flex-shrink-0 px-4">
          <span className="inline-flex text-2xl text-white font-extrabold">
            edtraction
          </span>
          <span className="pl-2 inline-flex text-sm text-white font-normal">
            admin
          </span>
        </div>
        <div className="mt-5 flex-1 flex flex-col">
          <nav className="flex-1 px-2 pb-4 space-y-1">
            <NavLink
              to="/"
              key={"home"}
              className={({ isActive }) =>
                classNames(
                  isActive
                    ? "bg-primary-800 text-white"
                    : "text-primary-100 hover:bg-primary-600",
                  "group flex items-center px-2 py-2 text-sm font-medium rounded-md mb-2"
                )
              }
            >
              <HomeIcon
                className="mr-3 flex-shrink-0 h-6 w-6 text-primary-300"
                aria-hidden="true"
              />
              {"Home"}
            </NavLink>

            {navigation.map((item) => (
              <NavLink
                to={item.href}
                key={item.href}
                className={({ isActive }) =>
                  classNames(
                    isActive
                      ? "bg-primary-800 text-white"
                      : "text-primary-100 hover:bg-primary-600",
                    "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                  )
                }
              >
                <item.icon
                  className="mr-3 flex-shrink-0 h-6 w-6 text-primary-300"
                  aria-hidden="true"
                />
                {item.name}
              </NavLink>
            ))}

            <button
              key={"logout"}
              type="button"
              onClick={onLogout}
              className={classNames(
                "text-primary-100 hover:bg-primary-600 group w-full flex items-center px-2 py-2 text-sm font-medium rounded-md mb-10"
              )}
            >
              <LogoutIcon
                className={classNames(
                  "mr-3 flex-shrink-0 h-6 w-6 text-primary-300"
                )}
                aria-hidden="true"
              />
              Logout
            </button>
          </nav>
        </div>
      </div>
    </div>
  );
}
