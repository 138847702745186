import { LogoutIcon } from "@heroicons/react/outline";
import { useAuth } from "../context/AuthProvider";

export const Login = () => {
  const { onLogin } = useAuth();
  return (
    <div className="flex h-screen">
      <div className="m-auto">
        <button
          type="button"
          onClick={onLogin}
          className="inline-flex items-center px-6 py-3 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
        >
          Logga In
          <LogoutIcon className="ml-3 -mr-1 h-5 w-5" aria-hidden="true" />
        </button>
      </div>
    </div>
  );
};
