import { useState } from "react";
import { API } from "../env";
import { useProject } from "../context/ProjectProvider";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import slugify from "slugify";

const slugifyOptions = {
  remove: /[*+~.()'"!:@/,]/g,
  lower: true,
};

export default function SubjectsAdd() {
  const { project } = useProject();
  const url = API + "/subjects/add?site=" + project.id;
  const navigate = useNavigate();

  const [subject, setSubject] = useState({
    id: "",
    label: "",
    slug: "",
    type: "subject",
    data: { description: "" },
  });
  const [loading, setLoading] = useState(false);

  const saveSubject = async () => {
    setLoading(true);
    await axios.post(url, subject);
    navigate("/subjects/" + subject.id);
    setLoading(false);
  };

  return (
    <div className="md:pl-64 flex flex-col flex-1">
      <main className="flex-1 min-h-screen">
        <div className="py-6">
          <div className=" mx-auto px-4 sm:px-6 md:px-8">
            <h1 className="text-2xl font-semibold text-gray-900">
              Create new subject
            </h1>
          </div>
          <div className="px-4 sm:px-6 lg:px-8">
            <div className="sm:flex sm:items-center">
              <div className="sm:flex-auto">
                <p className="mt-2 text-sm text-gray-700">
                  Create a new subject below and save
                </p>
              </div>
            </div>
            <div className="mt-8 overflow-x-auto sm:-mx-6 lg:-mx-8 border-t"></div>

            {subject ? (
              <div className="flex flex-col">
                <div className="overflow-x-auto">
                  <div className="inline-block min-w-full py-2 align-middle p-0">
                    <div className="overflow-hidden shadow-sm">
                      <div className="mb-2">
                        <label
                          htmlFor="id"
                          className="block text-sm font-medium text-gray-700"
                        >
                          ID
                        </label>
                        <div className="mt-1">
                          <input
                            autoFocus
                            type="text"
                            className="form-control block max-w-sm px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border-2 border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-primary-600 focus:outline-none "
                            id="projectList"
                            placeholder="s2005"
                            value={subject.id}
                            onChange={(e) =>
                              setSubject({ ...subject, id: e.target.value })
                            }
                          />
                        </div>
                      </div>
                      <div className="mb-2">
                        <label
                          htmlFor="id"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Label
                        </label>
                        <div className="mt-1">
                          <input
                            type="text"
                            className="form-control block max-w-sm px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border-2 border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-primary-600 focus:outline-none "
                            id="projectList"
                            placeholder="Data science"
                            value={subject.label}
                            onChange={(e) =>
                              setSubject({
                                ...subject,
                                label: e.target.value,
                                slug: slugify(e.target.value, slugifyOptions),
                              })
                            }
                          />
                        </div>
                      </div>

                      <div className="mb-2">
                        <span className=" text-sm font-medium text-gray-700">
                          Slug:
                        </span>
                        <span className="text-sm font-semibold text-gray-700 ml-1">
                          {subject.slug}
                        </span>
                      </div>
                    </div>

                    <button
                      onClick={() => saveSubject()}
                      className="mt-4 flex justify-center items-center px-4 py-2  text-sm font-medium rounded-md bg-primary-600 text-white"
                    >
                      {loading ? (
                        <svg
                          className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          />
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          />
                        </svg>
                      ) : (
                        ""
                      )}
                      {loading ? " " : "Save "}
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </main>
    </div>
  );
}
