import { Outlet } from "react-router-dom";
import Navigation from "./Navigation";
import Stickytop from "./Stickytop";

export default function Portal() {
  return (
    <div>
      <Navigation />

      <div className="flex flex-col flex-1">
        <div className="pl-64">
          <Stickytop />
        </div>
        <Outlet></Outlet>
      </div>
    </div>
  );
}
