import "./App.css";

import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AuthProvider } from "./context/AuthProvider";
import { Dashboard } from "./routes/Dashboard";
import { ProtectedRoute } from "./components/ProtectedRoute";
import { Login } from "./routes/Login";
import Portal from "./components/Portal";
import Subjects from "./routes/Subjects";
import Blogposts from "./routes/Blogposts";
import { ProjectProvider } from "./context/ProjectProvider";
import SubjectsEdit from "./routes/SubjectsEdit";
import SubjectsAdd from "./routes/SubjectAdd";
import BlogpostsAdd from "./routes/BlogpostsAdd";
import BlogpostEdit from "./routes/BlogpostsEdit";
import { Metadata } from "./routes/Metadata";
import SitesAdd from "./routes/SitesAdd";
import SSYK from "./routes/SSYK";

const App = () => {
  return (
    <>
      <Router>
        <AuthProvider>
          <ProjectProvider>
            <Routes>
              <Route path="login" element={<Login />} />

              <Route element={<Portal />}>
                <Route
                  path="/"
                  element={
                    <ProtectedRoute>
                      <Dashboard />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/sites/add"
                  element={
                    <ProtectedRoute>
                      <SitesAdd />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/metadata"
                  element={
                    <ProtectedRoute>
                      <Metadata />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/subjects"
                  element={
                    <ProtectedRoute>
                      <Subjects />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/subjects/add"
                  element={
                    <ProtectedRoute>
                      <SubjectsAdd />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/subjects/:id"
                  element={
                    <ProtectedRoute>
                      <SubjectsEdit />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/blogposts"
                  element={
                    <ProtectedRoute>
                      <Blogposts />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/blogposts/add"
                  element={
                    <ProtectedRoute>
                      <BlogpostsAdd />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/blogposts/:id"
                  element={
                    <ProtectedRoute>
                      <BlogpostEdit />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/ssyk"
                  element={
                    <ProtectedRoute>
                      <SSYK />
                    </ProtectedRoute>
                  }
                />
              </Route>
            </Routes>
          </ProjectProvider>
        </AuthProvider>
      </Router>
    </>
  );
};

export default App;
