import { PlusSmIcon, SearchIcon } from "@heroicons/react/outline";
import { useEffect, useState } from "react";
import { API, API_URL } from "../env";
import { PencilAltIcon } from "@heroicons/react/solid";
import { Link } from "react-router-dom";

import FullWithLoader from "../components/FullWithLoader";
export default function SSYK() {
  const [category, setCategory] = useState("ssyk4");

  const url = API_URL + "/" + category + "?size=500";
  const [fields, setFields] = useState([]);

  const [filteredFields, setFilteredFields] = useState([]);

  useEffect(() => {
    const getDatas = async () => {
      const response = await fetch(url);
      let data = await response.json();
      data = data.items;

      data.sort((a, b) =>
        a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1
      );

      setFields(data);
      setFilteredFields(data);
    };
    getDatas();
  }, [url, category]);

  const inputChange = (event) => {
    if (event.target.value) {
      setFilteredFields(
        fields.filter((s) =>
          s.name.toLowerCase().includes(event.target.value.toLowerCase())
        )
      );
    } else {
      setFilteredFields(fields);
    }
  };

  return (
    <div className="md:pl-64 flex flex-col flex-1">
      <main className="flex-1 min-h-screen">
        <div className="py-6">
          <div className=" mx-auto px-4 sm:px-6 md:px-8">
            <h1 className="text-2xl font-semibold text-gray-900">
              Occupations
            </h1>
          </div>
          <div className="px-4 sm:px-6 lg:px-8">
            <div className="sm:flex sm:items-center">
              <div className="sm:flex-auto">
                <p className="mt-2 text-sm text-gray-700">
                  List of occuptation fields
                </p>
              </div>
              <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                <button
                  type="button"
                  onClick={() => setCategory("ocf")}
                  className="inline-flex mr-4 items-center justify-center rounded-md border border-transparent bg-primary-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:w-auto"
                >
                  Fields
                </button>
                <button
                  type="button"
                  onClick={() => setCategory("ssyk1")}
                  className="inline-flex mr-4 items-center justify-center rounded-md border border-transparent bg-primary-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:w-auto"
                >
                  SSYK1
                </button>
                <button
                  type="button"
                  onClick={() => setCategory("ssyk2")}
                  className="inline-flex mr-4 items-center justify-center rounded-md border border-transparent bg-primary-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:w-auto"
                >
                  SSYK2
                </button>
                <button
                  type="button"
                  onClick={() => setCategory("ssyk3")}
                  className="inline-flex mr-4 items-center justify-center rounded-md border border-transparent bg-primary-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:w-auto"
                >
                  SSYK3
                </button>
                <button
                  type="button"
                  onClick={() => setCategory("ssyk4")}
                  className="inline-flex mr-4 items-center justify-center rounded-md border border-transparent bg-primary-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:w-auto"
                >
                  SSYK4
                </button>

                <button
                  type="button"
                  onClick={() => setCategory("ssyk5")}
                  className="inline-flex mr-4 items-center justify-center rounded-md border border-transparent bg-primary-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:w-auto"
                >
                  SSYK5
                </button>
              </div>
            </div>
            <div className="mt-8 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full   align-middle">
                <div className="overflow-hidden shadow-sm border-t px-6 py-2">
                  <form className="w-full flex md:ml-0" action="#" method="GET">
                    <label htmlFor="search-field" className="sr-only">
                      Search
                    </label>
                    <div className="relative w-full text-gray-400 focus-within:text-gray-600">
                      <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
                        <SearchIcon className="h-5 w-5" aria-hidden="true" />
                      </div>
                      <input
                        onChange={(event) => inputChange(event)}
                        id="search-field"
                        className="block w-full h-full pl-8 pr-3 py-2 border-transparent text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm"
                        placeholder="Search"
                        type="search"
                        name="search"
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div className="flex flex-col">
              <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle">
                  <div className="overflow-hidden shadow-sm border-t">
                    {filteredFields.length > 0 ? (
                      <table className="min-w-full divide-y divide-gray-300">
                        <thead className="bg-gray-50">
                          <tr>
                            <th
                              scope="col"
                              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:pl-8"
                            >
                              Name
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              ID
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              SSYK
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              Description
                            </th>
                            <th
                              scope="col"
                              className="relative py-3.5 pl-3 pr-4 sm:pr-6 lg:pr-8"
                            >
                              <span className="sr-only">Edit</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          {filteredFields.map((field) => (
                            <tr key={field.id}>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8">
                                {field.name}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {field.id}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {field.ssyk}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {field.description
                                  ? field.description.substring(0, 70) + "..."
                                  : ""}
                              </td>
                              <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 lg:pr-8"></td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      <FullWithLoader />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
