import { PlusIcon, SelectorIcon } from "@heroicons/react/outline";
import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useProject } from "../context/ProjectProvider";
import { API } from "../env";
import { Link } from "react-router-dom";

// const sites = [
//   { id: "yhutbildningarnu", domain: "yhutbildningar.nu" },
//   { id: "byggutbildningarse", domain: "byggutbildningar.se" },
//   { id: "pluggait", domain: "plugga.it" },
//   { id: "masterprogramse", domain: "masterprogram.se" },
// ];

export default function Stickytop() {
  const [open, setOpen] = useState(false);
  const [sites, setSites] = useState([]);

  const [filteredSites, setFilteredSites] = useState(sites);
  const { selectProject, project } = useProject();

  const url = API + "/sites";

  useEffect(() => {
    const getDatas = async () => {
      const response = await fetch(url);
      const data = await response.json();

      data.sort((a, b) => (a.id > b.id ? -1 : 1));

      setSites(data);
      setFilteredSites(data);
    };
    getDatas();
  }, [url, project]);

  const setProject = (project) => {
    selectProject(project);
    closeModal();
  };

  const inputChange = (event) => {
    if (event.target.value) {
      setFilteredSites(
        filteredSites.filter((s) => s.id.startsWith(event.target.value))
      );
    } else {
      setFilteredSites(sites);
    }
  };

  function closeModal() {
    setOpen(false);
  }

  function openModal() {
    setOpen(true);
  }
  return (
    <>
      <div className="sticky top-0 z-10 flex-shrink-0 flex h-12 bg-white shadow">
        <div className="flex-1 px-4 flex justify-between">
          <div className="flex-1 flex">
            <form
              className="w-full flex md:ml-0"
              action="#"
              method="GET"
            ></form>
          </div>
          <div className="ml-4 flex items-center md:ml-6">
            <Link to={"/sites/add"}>
              <button
                type="button"
                className="inline-flex items-center px-4 py-2 mr-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
              >
                Create
                <PlusIcon className="ml-2 -mr-1 h-5 w-5" aria-hidden="true" />
              </button>
            </Link>
            <button
              type="button"
              onClick={() => openModal()}
              className="inline-flex items-center px-4 py-2 border-2 border-gray-200 shadow-sm font-semibold text-sm rounded-md text-gray-700 bg-gray-100 hover:bg-gray-200 focus:outline-gray-200 focus:ring-offset-2 focus:ring-gray-500"
              data-modal-toggle="defaultModal"
            >
              {project ? project.domain : "Select project"}
              <SelectorIcon className="ml-3 -mr-1 h-5 w-5" aria-hidden="true" />
            </button>
            {/* <button
            type="button"
            className="bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
          >
            <span className="sr-only">View notifications</span>
            <BellIcon className="h-6 w-6" aria-hidden="true" />
          </button> */}
          </div>
        </div>
      </div>

      {/* Select project modal */}

      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-sm sm:w-full sm:p-6">
                  <div>
                    <label
                      htmlFor="projectList"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Filter projects
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border-2 border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-primary-600 focus:outline-none "
                        id="projectList"
                        onChange={(event) => inputChange(event)}
                        placeholder="search project..."
                      />
                    </div>
                  </div>
                  <ul className="divide-y divide-gray-200">
                    {filteredSites.map((site, idx) => (
                      <li
                        key={site.id + idx}
                        className="py-4 flex cursor-pointer"
                        onClick={() => setProject(site)}
                      >
                        <div className="ml-3">
                          <p className="text-sm font-medium text-gray-900">
                            {site.domain}
                          </p>
                        </div>
                      </li>
                    ))}
                  </ul>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
