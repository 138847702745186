import { useEffect, useRef, useState } from "react";
import { API } from "../env";
import { useProject } from "../context/ProjectProvider";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { Editor } from "@tinymce/tinymce-react";

export default function SubjectsEdit() {
  const { project } = useProject();
  const { id } = useParams();
  const url = API + "/subjects/" + id + "?site=" + project.id;
  const [subject, setSubject] = useState(null);
  const [loading, setLoading] = useState(false);
  const editorRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const getDatas = async () => {
      const response = await fetch(url);
      const data = await response.json();
      if (!data.id) {
        navigate("/subjects");
      } else {
        setSubject(data);
      }
    };
    getDatas();
  }, [url, id, navigate]);

  const saveSubject = async () => {
    if (editorRef.current) {
      let content = editorRef.current.getContent();
      let newSubject = {
        ...subject,
        data: { ...subject.data, description: content },
      };
      setSubject(newSubject);
      setLoading(true);
      await axios.post(url, newSubject);
      setLoading(false);
    }
  };

  return (
    <div className="md:pl-64 flex flex-col flex-1">
      <main className="flex-1 min-h-screen">
        <div className="py-6">
          <div className=" mx-auto sm:px-6 md:px-8">
            <h1 className="text-2xl font-semibold text-gray-900">
              Edit subject{" "}
              <span className="underline">
                {subject ? '"' + subject.label + '"' : ""}
              </span>
            </h1>
          </div>
          <div className="px-4 sm:px-6 lg:px-8">
            <div className="sm:flex sm:items-center">
              <div className="sm:flex-auto">
                <p className="mt-2 text-sm text-gray-700">
                  Edit the subject below and save
                </p>
              </div>
            </div>
            <div className="mt-8 overflow-x-auto sm:-mx-6 lg:-mx-8 border-t"></div>

            {subject ? (
              <div className="flex flex-col">
                <div className="overflow-x-auto">
                  <div className="inline-block min-w-full py-2 align-middle p-0">
                    <div className="overflow-hidden">
                      <div className="mb-2">
                        <span className=" text-sm font-semibold text-gray-700">
                          ID:
                        </span>
                        <span className="text-sm font-semibold text-gray-700 ml-1">
                          {subject.id}
                        </span>
                      </div>
                      <div className="mb-2">
                        <span className=" text-sm font-medium text-gray-700">
                          Project:
                        </span>
                        <span className="text-sm font-semibold text-gray-700 ml-1">
                          {project.id}
                        </span>
                      </div>
                      <div className="mb-2">
                        <span className=" text-sm font-medium text-gray-700">
                          Slug:
                        </span>
                        <span className="text-sm font-semibold text-gray-700 ml-1">
                          {subject.slug}
                        </span>
                      </div>
                      {/* 
                      <div>
                        <label
                          htmlFor="about"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Description
                        </label>
                        <div className="mt-1">
                          <textarea
                            id="about"
                            name="about"
                            rows={8}
                            className="max-w-4xl shadow-sm text-gray-700 focus:ring-primary-500 focus:border-primary-500 mt-1 block w-full sm:text-sm border-2 border-gray-300 rounded-md p-4"
                            placeholder="you@example.com"
                            onChange={(event) =>
                              setSubject({
                                ...subject,
                                data: { description: event.target.value },
                              })
                            }
                            value={subject.data ? subject.data.description : ""}
                          />
                        </div>
                      </div> */}

                      <div className="mb-2 max-w-3xl">
                        <span className="block text-sm bg-primary-200 rounded-r-full p-2 font-normal">
                          Tips:{" "} Väv in nyckelord som <span className="font-bold">antagningspoäng, lön, "plugga till", utbildningar, antagning, utomlands</span>
                        </span>
                      </div>

                      <div className="mb-2">
                        <label
                          htmlFor="id"
                          className="block text-sm font-medium text-gray-700"
                        >
                          SEO Title
                        </label>
                        <div className="mt-1 max-w-4xl">
                          <input
                            type="text"
                            className="w-full block  max-w-3xl px-3 py-1.5 font-normal text-gray-700 bg-white border-2 border-solid border-gray-300 rounded transition ease-in-out focus:text-gray-700 focus:bg-white focus:border-primary-600 focus:outline-none "
                            id="projectList"
                            placeholder="Data science"
                            value={subject.data ? subject.data.seoTitle : ""}
                            onChange={(e) =>
                              setSubject({
                                ...subject,
                                data: {
                                  ...subject.data,
                                  seoTitle: e.target.value,
                                },
                              })
                            }
                          />
                        </div>
                      </div>

                      <div>
                        <label
                          htmlFor="about"
                          className="block text-sm font-medium text-gray-700"
                        >
                          SEO Description
                        </label>
                        <div className="mt-1">
                          <textarea
                            id="about"
                            name="about"
                            rows={4}
                            className="max-w-3xl shadow-sm text-gray-700 focus:ring-primary-500 focus:border-primary-500 mt-1 block w-full sm:text-sm border-2 border-gray-300 rounded-md p-4"
                            placeholder="Some good SEO description"
                            onChange={(event) =>
                              setSubject({
                                ...subject,
                                data: {
                                  ...subject.data,
                                  seoDescription: event.target.value,
                                },
                              })
                            }
                            value={
                              subject.data ? subject.data.seoDescription : ""
                            }
                          />
                        </div>
                      </div>
                      <div className="max-w-4xl shadow-xl mt-10">
                        <h3>Description</h3>
                        <Editor
                          onInit={(evt, editor) => (editorRef.current = editor)}
                          branding={false}
                          initialValue={
                            subject.data ? subject.data.description : ""
                          }
                          init={{
                            height: 300,
                            plugins: [
                              "emoticons",
                              "autolink",
                              "link",
                              "wordcount",
                              "autosave",
                              "lists",
                              "advlist",
                            ],
                            toolbar:
                              "formatselect | fontsizeselect |  numlist bullist | link | emoticons | autolink | undo redo | formatselect | bold italic backcolor |  alignleft aligncenter alignright alignjustify |  outdent indent | removeformat | wordcount",
                          }}
                        />
                      </div>
                    </div>

                    <button
                      onClick={() => saveSubject()}
                      className="mt-4 flex justify-center items-center px-4 py-2  text-sm font-medium rounded-md bg-primary-600 text-white"
                    >
                      {loading ? (
                        <svg
                          className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          />
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          />
                        </svg>
                      ) : (
                        ""
                      )}
                      {loading ? " " : "Save "}
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </main>
    </div>
  );
}
