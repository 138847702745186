import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useProject } from "../context/ProjectProvider";
import { API } from "../env";

export const Metadata = () => {
  const { project } = useProject();
  let [editor, setEditor] = useState(null);
  const editorRef = useRef(null);
  const [loading, setLoading] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const createEditor = () => {
    if (!editor) {
      setEditor(new window.JSONEditor(editorRef.current, {}));
    }
  };

  useEffect(() => {
    const getDatas = async () => {
      createEditor();
      const response = await fetch(API + "/metadata?site=" + project.id);
      const data = await response.json();
      if (editor) {
        editor.set(data);
      }
    };
    getDatas();
  }, [project, editor, createEditor]);

  const saveMetadata = async () => {
    setLoading(true);
    const data = editor.get();
    await axios.post(API + "/metadata?site=" + project.id, data);
    setLoading(false);
  };

  return (
    <div className="md:pl-64 flex flex-col flex-1">
      <main className="flex-1 min-h-screen">
        <div className="py-6">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <h1 className="text-2xl font-semibold text-gray-900">Metadata</h1>
            <p className="mt-2 text-sm text-gray-700">
              Update metadata settings for a site. This is a bit dangerous
              please be careful.
            </p>
          </div>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <div className="py-4">
              <div
                ref={editorRef}
                id="jsoneditor"
                className="w-full h-full"
              ></div>
            </div>

            <button
              onClick={() => saveMetadata()}
              className="mt-4 flex justify-center items-center px-4 py-2  text-sm font-medium rounded-md bg-primary-600 text-white"
            >
              {loading ? (
                <svg
                  className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  />
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  />
                </svg>
              ) : (
                ""
              )}
              {loading ? " " : "Save "}
            </button>
          </div>
        </div>
      </main>
    </div>
  );
};
