import { LightningBoltIcon, SearchIcon } from "@heroicons/react/outline";
import axios from "axios";
import { useEffect, useState } from "react";
import FullWithLoader from "../components/FullWithLoader";
import { useProject } from "../context/ProjectProvider";
import { API } from "../env";

export const Dashboard = () => {
  const { project } = useProject();
  const [loading, setLoading] = useState(true);
  const [metadata, setMetadata] = useState(false);

  const revalidate = async (hook) => {
    await axios.post(hook, {});
  };

  const recreateAutocomplete = async (site) => {
    await axios.get(API + "/elastic/autocomplete?site=" + site);
  };

  const recreateAllAutocomplete = async () => {
    await axios.get(API + "/elastic/autocomplete/all");
  };

  const rebuildMainIndex = async () => {
    await axios.get(API + "/elastic/search/rebuild");
  };

  const regenerateSitemap = async (site) => {
    await axios.get(API + "/sitemaps?site=" + site);
  };

  const regenerateAllSitemaps = async () => {
    await axios.get(API + "/sitemaps/all");
  };

  useEffect(() => {
    const getDatas = async () => {
      setLoading(true);
      const response = await fetch(API + "/metadata?site=" + project.id);
      const data = await response.json();
      setMetadata(data);
      setLoading(false);
    };
    getDatas();
  }, [project]);

  return (
    <div className="md:pl-64 flex flex-col flex-1">
      <main className="flex-1 min-h-screen">
        <div className="py-6">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <h1 className="text-2xl font-semibold text-gray-900">Dashboard</h1>
            <p className="mt-2 text-sm text-gray-700">
              All actions are asynchronous. They will run in the background once
              clicked!
            </p>
          </div>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            {/* Replace with your content */}

            {loading ? (
              <FullWithLoader />
            ) : (
              <div className="py-4">
                <div className="border-4 border-dashed border-gray-200 rounded-lg p-10">
                  <div className="">
                    <span className="block text-lg font-semibold text-gray-900">
                      General actions
                    </span>
                    <button
                      type="button"
                      className="inline-flex mt-4 items-center px-4 py-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                      onClick={() => rebuildMainIndex()}
                    >
                      Rebuild main index
                      <SearchIcon
                        className="ml-3 -mr-1 h-5 w-5"
                        aria-hidden="true"
                      />
                    </button>
                    <br />
                    <button
                      type="button"
                      className="inline-flex mt-4 items-center px-4 py-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                      onClick={() => recreateAllAutocomplete()}
                    >
                      Recreate autocomplete for all sites
                      <SearchIcon
                        className="ml-3 -mr-1 h-5 w-5"
                        aria-hidden="true"
                      />
                    </button>
                    <br />
                    <button
                      type="button"
                      className="inline-flex mt-4 items-center px-4 py-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                      onClick={() => regenerateAllSitemaps()}
                    >
                      Regenerate all sitemaps
                      <SearchIcon
                        className="ml-3 -mr-1 h-5 w-5"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                  <div className="mt-10 ">
                    <span className="block text-lg font-semibold text-gray-900">
                      Site specific actions
                    </span>
                    <button
                      type="button"
                      className="inline-flex mb-4 mt-4 items-center px-4 py-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                      onClick={() => revalidate(metadata.revalidateHook)}
                    >
                      Revalidate {metadata.domain}
                      <LightningBoltIcon
                        className="ml-3 -mr-1 h-5 w-5"
                        aria-hidden="true"
                      />
                    </button>
                    <br />
                    <button
                      type="button"
                      className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                      onClick={() => recreateAutocomplete(metadata.site)}
                    >
                      Recreate autocomplete {metadata.domain}
                      <SearchIcon
                        className="ml-3 -mr-1 h-5 w-5"
                        aria-hidden="true"
                      />
                    </button>
                    <br />
                    <button
                      type="button"
                      className="inline-flex mt-4 items-center px-4 py-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                      onClick={() => regenerateSitemap(metadata.site)}
                    >
                      Regenerate sitemap {metadata.domain}
                      <SearchIcon
                        className="ml-3 -mr-1 h-5 w-5"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                  {/* Redeploy specific url through isR */}
                </div>
              </div>
            )}
          </div>
        </div>
      </main>
    </div>
  );
};
