import React from "react";

export const ProjectContext = React.createContext(null);

export const useProject = () => {
  return React.useContext(ProjectContext);
};

export const ProjectProvider = ({ children }) => {
  const defaultProject = { id: "pluggait", domain: "plugga.it" };

  const [project, setProject] = React.useState(defaultProject);

  const selectProject = async (project) => {
    console.log("selected " + project.id);
    setProject(project);
  };
  const value = {
    project,
    selectProject,
  };

  return (
    <ProjectContext.Provider value={value}>{children}</ProjectContext.Provider>
  );
};
