export const API = "https://oaeci4diq2.execute-api.eu-west-1.amazonaws.com";
export const API_URL = "https://api.edtraction.com/";
export const SITE_API =
  "https://t1lp9d9g4a.execute-api.eu-west-1.amazonaws.com/prod";

export const defaultMetadata = {
  aboutDescriptionP1:
    "Plugga.it samlar alla möjligheter till att utbilda sig inom IT på ett ställe. Oavsett om du vill läsa till civilingenjör på universitet eller snabbt komma ut i arbetslivet via YH så hittar du relevanta utbildningar här. Vi sammanställe kontinuerligt information från Skolverket, UHR, arbetsförmedlingen och SCB för att ge en uppdaterad bild av läget i branschen.",
  aboutDescriptionP2:
    "IT-branschen skriker idag efter mer kompetens. Många företag i Sverige har brist på allt ifrån programmerare till IT kunniga projektledare. Därför är en utbildning inom IT ett säkert karriärval i dagens samhälle.",
  aboutHeadingH1: "Om sidan",
  autocompleteIndex: "pluggaitautocomplete",
  baseFilters: "&a=2",
  builDaily: false,
  defaultSearchFilter: [
    {
      field: "areaIds",
      value: "2",
    },
  ],
  domain: "plugga.it",
  DOMAIN: "plugga.it",
  footerDescription:
    "Samlar och sammanställer information och utbildningar inom IT och programmering.",
  FOOTER_DESCRIPTION:
    "Samlar och sammanställer alla Sveriges högskole- och yrkeshögskoleutbildningar på ett ställe.",
  generateSitemap: false,
  GLTD: "it",
  gtld: "it",
  GTM: "G-ZBWJ02YWWY",
  indexDescription:
    "Då väntar dig roliga arbetsuppgifter i en stekhet bransch! Bra löner, fantastiska förmåner och stora möjligheter att jobba utomlands. En ljus framtid väntar den som pluggar något inom IT",
  indexHeadingH1: "Vill du plugga IT och programmering?",
  indexHeadingH2: "Populära utbildningsområden",
  indexHeadingH3: "Utbildningstyper",
  indexMetaDescripion:
    "Hitta utbildningar inom IT och programmering. Branschen är stekhet, lönerna är bra och jobb finns det gott om! Börja plugga nu och säkra din framtid.",
  indexTitle: "Utbildningar inom IT och programmering",
  INDEX_SEO_TITLE: "Hitta sök och jämför utbildningar - plugga.it",
  INDEX_TITLE: "Hitta sök och jämför utbildningar",
  locationIndexDescription:
    "Här listas vi alla orter där det finns IT-utbildningar. Klicka in på den orten där du vill studera för att hitta bra it-utbildningar.",
  locationIndexH1: "Orter",
  menuItems: [
    {
      name: "Blogg",
      slug: "/blogg",
    },
    {
      name: "Om sidan",
      slug: "/om",
    },
  ],
  NAME: "pluggait",
  pingGoogleSitemap: false,
  providerDescriptionP1: "Om du vill lästa intressanta utbildningar på",
  providerDescriptionP2:
    "så har du kommit rätt. Här listar vi alla IT-utbildningar du kan läsa på",
  providerIndexDescription:
    "Här listas alla högskolor, universitet och yrkeshögskolor som erbjuder någon typ av IT-relaterad utbildning. Om du klickar dig vidare på någon skola hittar du mer information om utbildningarna.",
  providerMetaDescriptionP1: "Plugga IT och programmering på ",
  providerMetaDescriptionP2:
    ". Här erbjuds flera moderna it-utbildningar som lägger en bra grund för en framtida karriär inom IT och programmering.",
  pvoiderIndexH1: "Skolor",
  repository: "plugga.it",
  searchIndex: "schoolmaster",
  seoName: "Utbildningar",
  SEP: " » ",
  separator: " » ",
  site: "pluggait",
  siteArea: 2,
  siteMapUrl: "https://www.sitemaps.pw/pluggait/sitemap.xml",
  sitemapUrl: "https://www.plugga.it",
  subjectDescriptionP1: "Hitta utbildningar för sökningen",
  subjectDescriptionP2:
    "Gå igenom listan och se ifall det finns någon utbildning du är intresserad av. Sen kan du klicka dig in på utbildnigen och få mer information.",
  subjectMetaDescriptionP1: "Plugga ",
  subjectMetaDescriptionP2:
    " och starta din karriär i IT branschen. Här hittar du bra utbildningar för ",
  subjectMetaDescriptionP3: " som tar dig vidare.",
  title: "plugga",
  type: "metadata",
};
